import {navData, navlist} from "@utils/data";
import {useMemo, useState} from "react";

const HeaderDropdown = ({
  nav,
  setNav,
  setShowProducts,
  setShowColor,
  showDownloadModal,
  setShowDownloadModal,
}: {
  nav?: string
  setNav?: (value: string) => void
  setShowProducts?: (value: boolean) => void
  setShowColor?: (value: boolean) => void
  showDownloadModal?: boolean
  setShowDownloadModal?: (showDownloadModal: boolean) => void
}) => {
  const [showMobile, setShowMobile] = useState(false);

  const clickedNav = useMemo(() => {
    if (nav === "Solutions") {
      return navData.solutions;
    }

    if (nav === "Products") {
      return navData.products;
    }

    if (nav === "Resources") {
      return navData.resources;
    }
  }, [nav]);

  // console.log(nav, "=======================clicked nav");

  const headerClick = (nav: string) => {
    console.log("nav item: ", nav);
    if (nav) {
      setNav && setNav(nav);
    }

    setShowMobile(true);
  };

  const openDownloadModal = () => {
    // Get the width of the screen
    const screenWidth = window.innerWidth;
    console.log(screenWidth);
    if (screenWidth >= 1024) {
      setShowDownloadModal && setShowDownloadModal(true);
    } else {
      window.open(process.env.NEXT_PUBLIC_GOOGLE_PLAYSTORE_URL, "_blank");
    }
  };

  return (
    <div className={`${window.innerWidth >= 1024 ? "dropdown-container" : ""}`}>
      <div
        className="uni-width header-dropdown"
        onMouseLeave={() => {
          setShowProducts && setShowProducts(false);
          setShowColor && setShowColor(false);
        }}
      >
        {nav === "mobile" && !showMobile && (
          <div className="uni-width ">
            {navlist.map((nav) => {
              return (
                <div
                  className="mob-first-nav"
                  key={nav.name}
                  onClick={() => nav.extra && headerClick(nav.name)}
                >
                  <p>{nav.name}</p>

                  {nav.extra && <img src="/assets/icons/mob-right-icon.svg" />}
                </div>
              );
            })}
          </div>
        )}

        {(nav !== "mobile" || (nav === "mobile" && showMobile)) && (
          <>
            <div className="header-cell">
              <p className="tetiary-cap mb-24">{clickedNav?.first.title}</p>

              {clickedNav?.first.sections.map((section) => {
                return (
                  <a
                    href={section?.url}
                    key={section.title}
                    target={!section?.internal ? "_blank" : "_self"}
                    rel="noreferrer"
                  >
                    <div className="dropdown-cell">
                      <div className="drop-mini">
                        <p className="text-primary five-16">{section.title}</p>
                        {section.description && (
                          <p className="text-secondary four-14 mt-1">
                            {section?.description}
                          </p>
                        )}
                      </div>

                      <div className="green-arrow-holder">
                        <img
                          src="/assets/icons/green-arrow.svg"
                          width={15}
                          // height={10}
                        />
                      </div>
                    </div>
                  </a>
                );
              })}
            </div>

            <div className="header-cell header-middle">
              <p className="tetiary-cap mb-24">{clickedNav?.second.title}</p>

              {clickedNav?.second.sections.map((section) => {
                return (
                  <a
                    href={section?.url}
                    key={section.title}
                    target={!section?.internal ? "_blank" : "_self"}
                    rel="noreferrer"
                  >
                    <div className="dropdown-cell">
                      <div className="drop-mini">
                        <p className="text-primary five-16">{section.title}</p>
                        {section?.description && (
                          <p className="text-secondary four-14 mt-1">
                            {section?.description}
                          </p>
                        )}
                      </div>

                      <div className="green-arrow-holder">
                        <img
                          src="/assets/icons/green-arrow.svg"
                          width={20}
                          // height={10}
                        />
                      </div>
                    </div>
                  </a>
                );
              })}
            </div>

            <div className="header-cell">
              <p className="tetiary-cap mb-24">{clickedNav?.third.title}</p>
              <img
                src={clickedNav?.third.image}
                alt="announcements"
                style={{width: "98%"}}
              />
              <p className="mt-16 five-16 text-primary">
                {clickedNav?.third.subTitle}
              </p>

              {clickedNav?.third?.url && (
                <a
                  className="web-learn text-link six-16 mt-16"
                  href={clickedNav?.third?.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="mr-8">Learn More</span>{" "}
                  <img
                    src="/assets/icons/green-arrow.svg"
                    width={13.33}
                    height={10}
                  />
                </a>
              )}

              {!clickedNav?.third?.url && (
                <div
                  className="web-learn text-link six-16 mt-16"
                  onClick={() => openDownloadModal()}
                >
                  <span className="mr-8">Download Now</span>{" "}
                  <img
                    src="/assets/icons/green-arrow.svg"
                    width={13.33}
                    height={10}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>

    // <div className="w-full bg-white-400 absolute shadow-sm header-product-container">
    // 	<div className="max-w-screen-xl mx-auto lg:px-16 flex-col flex lg:flex-row md:flex-row  z-100">
    // 		<div className="border-x flex-[3]">
    // 			<h2 className="ml-[15px] font-bold font-satoshi text-[21px] mt-[10px] header-section-h2">
    // 				Product Solutions
    // 			</h2>

    // 			<div className="w-full mt-[30px] flex justify-between px-[15px] flex-wrap">
    // 				{products.map((product) => (
    // 					<a
    // 						href={product.url}
    // 						className="w-[48%] mb-[20px]"
    // 						key={product.title}
    // 					>
    // 						<div>
    // 							<h3 className="product-category-header">{product.title}</h3>
    // 							<div className="product-category-text hidden-small">
    // 								{product.text}
    // 							</div>
    // 						</div>
    // 					</a>
    // 				))}
    // 			</div>
    // 		</div>

    // 		<div className="border-x  flex-[2] ">
    // 			<h2 className="ml-[15px] font-bold font-satoshi text-[21px] mt-[10px] header-section-h2">
    // 				Fresh and new in Lendsqr
    // 			</h2>

    // 			<div className="w-full mt-[30px] flex justify-between px-[15px] flex-wrap">
    // 				{freshNewArray.map((freshNew) => (
    // 					<div className="w-[48%] mb-[20px]" key={freshNew.title}>
    // 						<h3 className="product-category-header">{freshNew.title}</h3>
    // 						<div className="mt-[10px]">
    // 							<img
    // 								className="w-full hidden-small"
    // 								src={freshNew.image}
    // 								alt={freshNew.text}
    // 							/>
    // 						</div>

    // 						<p className="sqr-text hidden-small">{freshNew.text}</p>

    // 						{freshNew.linkText && (
    // 							<a href={freshNew.url}>
    // 								<div className="font-satoshi text-sm gap-2 flex mt-4 get-started">
    // 									{freshNew.linkText}{" "}
    // 									<Image
    // 										className="get-started-icon"
    // 										alt="arror right"
    // 										src="/assets/icons/arror-right-grey.svg"
    // 										width={8}
    // 										height={8}
    // 									/>
    // 								</div>
    // 							</a>
    // 						)}
    // 					</div>
    // 				))}
    // 			</div>
    // 		</div>
    // 	</div>
    // </div>
  );
};

export {HeaderDropdown};
