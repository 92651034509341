import {useMutation} from "@tanstack/react-query";

const body = ({email, message, name, phoneNumber, organization}: any) => ({
  context_id: process.env.NEXT_PUBLIC_CONTACT_CONTEXT_ID,
  sender: "Dara from Lendsqr<support@lendsqr.com>",
  recipient: organization.email,
  data: {
    organization: {
      name: organization.organization || "",
      short_name: organization.organization || "",
      support_email: organization.email || "",
      support_phone: organization.phone_number || "",
      address: organization.address || "",
      facebook_url: organization.facebook_url || "",
      twitter_url: organization.twitter_url || "",
      instagram_url: organization.instagram_url || "",
      privacy_url: organization.privacy_url || "",
      terms_url: organization.terms_url || "",
      url: organization.website || "",
      logo_url:
        organization.logo_url ||
        "https://documents.lendsqr.com/default/265449ed21dc65c90669912eb6a2f01104be6249b022c711f9dc87661f09817a3fb4098f3de9a07e70e2532ae2027e7e8c0cfaa715b1351cd9bf085b35d446f7.png",
    },
    notification: {
      message,
      email,
      phoneNumber,
      name,
    },
  },
});

const pricingBody = ({
  email,
  message,
  name,
  phoneNumber,
  service,
  request,
  plan,
}: any) => ({
  data: {
    message: "",
    email,
    phoneNumber,
    name,
    service,
    request,
    plan,
  },
});

const useContactLender = () => {
  return useMutation(
    async (data: any) => {
      await fetch("/api/email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body(data)),
      });
    },
    {
      onSuccess: (data) => {
        console.log(data);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );
};

const useContactPricing = () => {
  // const url = "/api/email";
  // const url = "https://lendsqr.com/api/email"
  // const url = `${process.env.NEXT_PUBLIC_NOTIFY_BASEURL}email/send`
  // REACT_APP_API_URL=https://vigil.lendsqr.com/pecunia/api
  // REACT_APP_UTILITY_URL=https://vigil.lendsqr.com/util
  const url =
    "https://vigil.lendsqr.com/pecunia/api/v1/meta/feedback/contact_us";

  return useMutation(
    async (data: any) => {
      try {
        await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(pricingBody({...data, message: ""})),
        });
      } catch (error) {
        console.log(error);
      }
    },
    {
      onSuccess: (data) => {
        console.log(data);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );
};

export {useContactLender, useContactPricing};
