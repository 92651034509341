import { IBtnProps } from "interfaces/button";
import Image from "next/image";

const Button = ({
	btnText,
	padX,
	padY,
	isBgColor,
	isImage,
	isSidebar,
	isLenders,
	loading,
	type,
	isBorder,
	onClick,
}: IBtnProps) => {
	const btnContent = (
		<>
			{btnText}
			{isImage ? (
				<div className="ml-4 mt-[.2rem]">
					<Image
						width={14}
						height={14}
						src="/assets/icons/arrow-up.svg"
						alt="Arrow up"
					/>
				</div>
			) : null}
		</>
	);

	return (
		<button
			type={type ? "button" : "submit"}
			className={`${isSidebar ? "w-full" : ""} ${
				isLenders ? "w-full" : "lg:w-auto "
			} justify-center flex font-bold text-[.9rem] -tracking-[0.02rem] ${
				isBgColor ?? "border-[1px] border-blue-200"
			} ${
				isBgColor && isBgColor !== "bg-white-100"
					? "text-white-100"
					: "text-blue-200"
			} ${padX} ${padY} rounded-[.645rem] ${
				isBorder ? "border-[1px] " + isBorder : ""
			}`}
			disabled={loading}
			onClick={() => {
				if (onClick) {
					onClick(true);
				}
			}}
		>
			{btnContent}
		</button>
	);
};
const LendsqrBtn = ({
	btnLink,
	btnText,
	padX,
	padY,
	isBgColor,
	isImage,
	isLeft,
	isSidebar,
	isLenders,
	loading,
	type,
	isBorder,
	onClick,
}: IBtnProps) => {
	return (
		<div
			className={` ${isSidebar ? "lg:flex" : "flex"} items-center ${
				isLeft ? "justify-start" : "justify-center"
			}`}
		>
			{onClick !== undefined ? (
				<Button
					btnText={btnText}
					padX={padX}
					padY={padY}
					isBgColor={isBgColor}
					isImage={isImage}
					isLeft={isLeft}
					isSidebar={isSidebar}
					isLenders={isLenders}
					loading={loading}
					type={type}
					isBorder={isBorder}
					onClick={onClick}
				/>
			) : (
				<a
					href={btnLink}
					target={isLenders ? "" : "_blank"}
					rel="noreferrer"
					className={`${isLenders && "w-full"}`}
				>
					<Button
						btnText={btnText}
						padX={padX}
						padY={padY}
						isBgColor={isBgColor}
						isImage={isImage}
						isLeft={isLeft}
						isSidebar={isSidebar}
						isLenders={isLenders}
						loading={loading}
						type={type}
						isBorder={isBorder}
					/>
				</a>
			)}
		</div>
	);
};

export { LendsqrBtn };
