const Cookie = ({setShowCookie}: {setShowCookie: (value: boolean) => void}) => {
  return (
    <div className="cookie-holder">
      <div className="uni-width cookie-inner">
        <p className="four-14 text-blue mr-32 flex-one">
          We use cookies to optimize our website and our service, ensuring you
          get the best experience on our platform. By clicking “Accept All”, you
          consent to the use of cookies for analytics, personalized content, and
          ads as per our Cookie Policy.
        </p>

        <div className="cookie-btn-holder">
          <button
            className="cookie-btn cookie-pale mr-6p"
            onClick={() => setShowCookie(false)}
          >
            Reject All
          </button>
          <button
            className="cookie-btn cookie-primary"
            onClick={() => setShowCookie(false)}
          >
            Accept All
          </button>
        </div>
      </div>
    </div>
  );
};

export {Cookie};
